import { breakpointValues } from '@finn/atoms/Theme';
import { cn } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import Head from 'next/head';
import React from 'react';

import {
  HeroCosmicMetadata,
  HeroType,
  ResponsiveImageSizes,
} from '~/types/UIModules';

import HeroContent from './HeroContent';
import { getOptimizedHeroImage, OptimizedImage } from './utils';

type StyledProps = {
  image?: {
    sm: OptimizedImage;
    md: OptimizedImage;
    lg: OptimizedImage;
  };
  isTertiaryHero: boolean;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    maxWidth: breakpointValues.max,
    margin: `${theme.spacing(0)} auto`,
    backgroundSize: '700% 100%',
    animation: '3s $shine linear infinite',
  },
  '@keyframes shine': {
    to: { backgroundPositionX: '-200%' },
  },
  wrapperImage: {
    width: '100%',
    minHeight: '196px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: (props: StyledProps) => `url(${props.image?.sm?.url})`,
    [theme.breakpoints.up('sm')]: {
      backgroundImage: (props: StyledProps) => `url(${props.image?.md?.url})`,
      minHeight: '376px',
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: (props: StyledProps) => `url(${props.image?.lg?.url})`,
      minHeight: '768px',
    },
  },
}));

type Props = {
  data: HeroCosmicMetadata;
};

const SecondaryHero: React.FunctionComponent<Props> = ({ data }) => {
  const { image, ...rest } = data;

  const heroType = rest.level.value;

  const responsiveImage = image && {
    sm: getOptimizedHeroImage(image, ResponsiveImageSizes.SMALL),
    md: getOptimizedHeroImage(image, ResponsiveImageSizes.MEDIUM),
    lg: getOptimizedHeroImage(image, ResponsiveImageSizes.LARGE),
  };

  const isTertiaryHero = heroType === HeroType.tertiary;

  const classes = useStyles({
    image: responsiveImage,
    isTertiaryHero,
  });

  return (
    <>
      {!isTertiaryHero && responsiveImage && (
        <Head>
          <link
            rel="preload"
            href={responsiveImage.sm?.url}
            as="image"
            media={`(max-width: ${responsiveImage.sm?.width}px)`}
          />
          <link
            rel="preload"
            href={responsiveImage.md?.url}
            as="image"
            media={`(max-width: ${responsiveImage.md?.width}px)`}
          />
          <link
            rel="preload"
            href={responsiveImage.lg?.url}
            as="image"
            media={`(max-width: ${responsiveImage.lg?.width}px)`}
          />
        </Head>
      )}

      <div className="container">
        <HeroContent
          title={rest.title}
          subtitle={rest.subtitle}
          heroType={heroType}
          cta={rest.cta}
          guideMetaData={rest.guide_meta_data?.metadata}
          label={rest.label}
          noImage={isTertiaryHero || !responsiveImage}
        />
      </div>

      {!isTertiaryHero && responsiveImage && (
        <div className={cn(classes.wrapper, 'bg-pewter')}>
          <div className={classes.wrapperImage} />
        </div>
      )}
    </>
  );
};

export default SecondaryHero;
